<template>
  <el-dialog
    :visible="showDialogAddPayment"
    @open="create"
    width="35%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template v-if="loaded">
		<v-row dense v-if="showPaymentMethods">
			<v-col cols="12" xl="4" lg="4" md="12" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 1 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(1, null)"
				>
					EFECTIVO
				</v-btn>
			</v-col> 
			<v-col cols="12" xl="4" lg="4" md="4" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 4 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(4, '01')"
				>
					VISA
				</v-btn>
			</v-col>
			<v-col cols="12" xl="4" lg="4" md="4" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 5 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(5, '02')"
				>
					MASTERCARD
				</v-btn>
			</v-col>
		</v-row>
		<v-row dense v-if="showPaymentMethods">
			<v-col cols="12" xl="4" lg="4" md="4" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 10 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(10, null)"
				>
					YAPE
				</v-btn>
			</v-col>
			<v-col cols="12" xl="4" lg="4" md="4" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 9 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(9, null)"
				>
					PLIN
				</v-btn> 
			</v-col>
			<v-col cols="12" xl="4" lg="4" md="4" sm="4">
				<v-btn
					:color="form.payment_method_type_id == 11 ? 'accent' : 'secondary'"
					class="btn-block"
					@click.prevent="selectPaymentMethod(11, null)"
				>
					MARKET
				</v-btn> 
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="12" xl="12" lg="12" md="12" sm="12">
				<label>
					MONTO
					<el-input-number 
						v-model="form.amount"
						controls-position="right"
						:min="0"
					>
					</el-input-number>
				</label>
			</v-col>
		</v-row>
    </template>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="close" plain>Cerrar</el-button>
      <el-button type="primary" @click="clickAddPayment">Agregar</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ["showDialogAddPayment", "showPaymentMethods"],
  data() {
    return {
      loaded: false,
      errors: {},
      form: {},
      company: {},
    };
  },
  methods: {
    initForm() {
      this.form = {
        payment_method_type_id: 1,
        card_brand_id: null,
        amount: null,
      };
    },
    async create() {
      this.initForm();
      this.loaded = true;
    },
    async clickAddPayment() {
      if (!Number(this.form.amount)) {
        return this.$message.error("Cantidad negativa o incorrecta");
      }
      await this.$emit("addPayment", this.form);
      await this.close();
    },
    selectPaymentMethod(payment_method_type_id, card_brand_id) {
      this.form.payment_method_type_id = payment_method_type_id;
      this.form.card_brand_id = card_brand_id;
    },
    clickFinalize() {
      this.$router.push({ name: "DocumentIndex" });
    },
    close() {
      this.$emit("update:showDialogAddPayment", false);
      this.initForm();
      this.loaded = false;
    },
  },
};
</script>
