<template>
	<div>
		<v-row>
			<v-col
				cols="12"
				xl="5"
				lg="5"
				md="5"
				sm="5"
				xs="12"
				style="height: calc(100vh - 65px);"
			>
				<div class="h-80" style="overflow-y: auto; overflow-x: hidden;">
					<v-row dense class="m-0 p-0">
						<v-col cols="12" xl="10" class="p-0 text-center">
							<h6 class="font-weight-semibold m-0">{{ customer.name }}</h6>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12" class="p-0">
							<v-simple-table dense>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-right">Cant.</th>
											<th class="text-left">Producto</th>
											<th class="text-right">Precio</th>
											<th class="text-right">Total</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in form.items"
											:key="index"
											class="hover"
										>
											<td width="20%" class="pr-1 pl-0 text-right">
												{{ item.quantity }}
											</td>
											<td width="50%" class="pa-0">
												{{ item.item.full_name }}
											</td>
											<td width="20%" class="pl-0 pr-1 text-right">
												{{ currencyTypeActive.symbol }}
												{{ item.unit_price }}
											</td>
											<td width="10%" class="pl-0 pr-1 text-right">
												{{ currencyTypeActive.symbol }} {{ item.total }}
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
					</v-row>
				</div>
				<!-- <div class="h-20" style="overflow-y: auto; overflow-x: hidden;">
					<v-divider class="my-4"></v-divider>
					<v-row dense>
						<v-col cols="12" xl="12" lg="12">
							<v-row dense>
								<v-col cols="12" xl="6" lg="6">
									<h6 class="mb-0 accent--text">SUBTOTAL</h6>
								</v-col>
								<v-col cols="12" xl="6" lg="6" class="text-right">
									<h6 class="mb-0 accent--text">
										{{ currencyTypeActive.symbol }} {{ form.total_taxed }}
									</h6>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" xl="6" lg="6">
									<h6 class="mb-0 error--text">IGV</h6>
								</v-col>
								<v-col cols="12" xl="6" lg="6" class="text-right">
									<h6 class="mb-0 error--text">
										{{ currencyTypeActive.symbol }} {{ form.total_igv }}
									</h6>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12" xl="6" lg="6">
									<h5 class="mb-0 success--text">TOTAL</h5>
								</v-col>
								<v-col cols="12" xl="6" lg="6" class="text-right">
									<h5 class="mb-0 success--text">
										{{ currencyTypeActive.symbol }} {{ form.total }}
									</h5>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</div> -->
			</v-col>
			<v-col cols="12" xl="7" lg="7" md="7" sm="7" class="pt-3 hyo secondary">
				<div style="height: calc(84vh - 110px);">
					<!-- <v-row dense no-gutters v-if="generate">
						<v-col cols="12" xl="6" lg="6">
							<v-btn
								block
								:color="form.document_type_id == '01' ? 'accent' : 'primary'"
								@click="changeDocumentType('01')"
							>
								FACTURA ELECTRÓNICA
							</v-btn>
						</v-col>
						<v-col cols="12" xl="6" lg="6">
							<v-btn
								block
								:color="form.document_type_id == '03' ? 'accent' : 'primary'"
								@click="changeDocumentType('03')"
							>
								BOLETA ELECTRÓNICA
							</v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" xl="12" lg="12" class="text-center">
							<el-checkbox v-model="generate">GENERAR COMPROBANTE</el-checkbox>
						</v-col>
					</v-row> -->
					<v-row dense v-if="generate">
						<v-col cols="12" xl="8" lg="8" md="8" sm="12">
							<div class="form-group">
								<!-- <label>Cliente</label> -->
								<el-select
									v-model="form.customer_id"
									filterable
									remote
									popper-class="el-select-customers"
									dusk="customer_id"
									placeholder="Buscar..."
									:remote-method="searchCustomers"
									:loading="loadingSearchCustomers"
									loading-text="Cargando..."
									@change="changeCustomer()"
								>
									<el-option
										v-for="option in customers"
										:key="option.id"
										:value="option.id"
										:label="option.description"
									></el-option>
								</el-select>
							</div>
						</v-col>
						<v-col cols="12" xl="4" lg="4" md="4" sm="12">
							<v-btn
								color="accent"
								class="btn-block"
								outlined
								@click="showDialogNewPerson = true"
								small
								>Nuevo cliente</v-btn
							>
						</v-col>
					</v-row>
					<!-- <v-row dense>
						<v-col cols="12" xl="12" class="text-center">
							<h6>MÉTODOS DE PAGO</h6>
						</v-col>
					</v-row> -->
					<v-row dense>
						<v-col cols="12" xl="6" lg="6" md="6" sm="12">
							<!-- <v-row dense>
								<v-col cols="12" xl="12" class="text-center">
									<h6>PAGO RÁPIDO EFECTIVO</h6>
								</v-col>
							</v-row> -->
							<v-row dense>
								<!-- <v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(10)"
										>{{ currencyTypeActive.symbol }} 10</v-btn
									>
								</v-col>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(20)"
										>{{ currencyTypeActive.symbol }} 20</v-btn
									>
								</v-col>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(50)"
										>{{ currencyTypeActive.symbol }} 50</v-btn
									>
								</v-col>
								<v-col cols="12" xl="2" lg="2" md="2" sm="2">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="setAmount(100)"
										>{{ currencyTypeActive.symbol }} 100</v-btn
									>
								</v-col> -->
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentExact"
									>
										EFECTIVO
									</v-btn>
								</v-col>
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentExactYape"
									>
										YAPE
									</v-btn>
								</v-col>
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentExactPlin"
									>
										PLIN
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" xl="6" lg="6" md="6" sm="12">
							<!-- <v-row dense>
								<v-col cols="12" xl="12" class="text-center">
									<h6>TARJETAS</h6>
								</v-col>
							</v-row> -->
							<v-row dense>
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentExactMarket"
									>
										MARKET
									</v-btn>
								</v-col>
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click.prevent="addPaymentWithCard('01', 4)"
										>VISA</v-btn
									>
								</v-col>
								<v-col cols="12" xl="4" lg="4" md="4" sm="4">
									<v-btn
										color="accent"
										class="btn-block"
										small
										@click="addPaymentWithCard('02', 5)"
										>MASTERCARD</v-btn
									>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12" xl="12">
							<div class="text-center">
								<h6 class="font-weight-medium">TOTAL A PAGAR</h6>
								<h5>{{ currencyTypeActive.symbol }} {{ form.total }}</h5>
							</div>
						</v-col>
						<v-col cols="12" xl="12" v-if="form.payments.length == 0">
							<div class="text-center">
								<img alt="discount" src="/static/images/credit-card.png" style="max-height: 96px"/>
								<!-- <p class="font-weight-medium">Aún no has añadido pagos</p> -->
							</div>
						</v-col>
						<v-col cols="12" xl="12" lg="12" md="12" v-else>
							<v-simple-table>
								<template v-slot:default>
									<!-- <thead>
                                    <tr>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Calories</th>
                                    </tr>
                                </thead> -->
									<tbody>
										<tr v-for="(row, index) in form.payments" :key="index">
											<td width="30%" class="align-middle">
												<v-icon color="info" left>mdi-credit-card</v-icon> 
												<strong>
													{{ row.payment_method.name.toUpperCase() }}
												</strong>
											</td>
											<td width="30%" class="text-right align-middle">
												<strong
													>{{ currencyTypeActive.symbol }}
													{{ row.payment }}</strong
												>
											</td>
											<td width="30%" class="text-right align-middle">
												<el-input v-model="row.reference"></el-input>
											</td>
											<td width="10%" class="text-right align-middle">
												<v-btn
													icon
													dark
													small
													color="error"
													@click.prevent="clickRemovePayment(index)"
												>
													<v-icon dark>mdi-delete</v-icon>
												</v-btn>
											</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>
						<v-col cols="12" xl="12">
							<div class="text-center">
								<v-btn color="accent" @click="addPaymentSelectMethod"
									>AÑADIR PAGO</v-btn
								>
							</div>
						</v-col>
					</v-row>
					<!-- <v-row dense>
                        <v-col cols="12" xl="6" lg="6">
                            <h5 class="mb-0 font-weight-medium">TOTAL PAGADO</h5>
                        </v-col>
                        <v-col cols="12" xl="6" lg="6" class="text-right">
                            <h5 class="mb-0 font-weight-bold">{{currencyTypeActive.symbol}} {{ form.total }}</h5>
                        </v-col>
                    </v-row> -->
				</div>

				<v-row>
					<v-col cols="12" xl="6" lg="6" md="6">
						<div class="text-center">
							<div :class="{ 'error--text': difference < 0 }">
								<h6
									class="font-weight-bold ma-0"
									v-text="difference < 0 ? 'FALTA' : 'VUELTO'"
								></h6>
								<h6 class="font-weight-bold ma-0">
									{{ currencyTypeActive.symbol }} {{ difference }}
								</h6>
							</div>
						</div>
					</v-col>
					<v-col cols="12" xl="6" lg="6" md="6">
						<div class="text-center">
							<h6 class="font-weight-bold ma-0">TOTAL PAGADO</h6>
							<h6 class="font-weight-bold ma-0">
								{{ currencyTypeActive.symbol }} {{ amount }}
							</h6>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<v-btn color="error" block outlined @click="clickCancel">CANCELAR VENTA</v-btn>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<v-btn color="info" block outlined @click="clickBack">AGREGAR PRODUCTOS</v-btn>
					</v-col>
					<v-col cols="12" xl="4" lg="4" md="4" sm="4">
						<v-btn
							color="accent"
							block
							@click="clickPayment"
							:disabled="button_payment"
							:loading="loadingSubmit"
							>FINALIZAR VENTA</v-btn
						>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<document-options
			:showDialog.sync="showDialogOptions"
			:recordId="documentNewId"
			:statusDocument="statusDocument"
			:isSaleReceivable="false"
			:isGenerate="false"
			:isFastSale="true"
			:showClose="false"
		>
		</document-options>

		<person-form
			:showDialog.sync="showDialogNewPerson"
			type="customers"
			:external="true"
			:document_type_id="form.document_type_id"
		>
		</person-form>

		<add-payment
			:showDialogAddPayment.sync="showDialogAddPayment"
			:showPaymentMethods="showPaymentMethods"
			@addPayment="addPayment"
		>
		</add-payment>
	</div>
</template>

<script>
import DocumentOptions from '../Documents/Component/Options';
import AddPayment from './Component/AddPayment';
import PersonForm from '../People/Form';
import dayjs from 'dayjs';
import filter from 'lodash/filter';
import round from 'lodash/round';
import find from 'lodash/find';

export default {
	components: {
		DocumentOptions,
		PersonForm,
		AddPayment,
	},
	props: ['form', 'customer', 'currencyTypeActive', 'exchangeRateSale'],
	data() {
		return {
			loadingSubmit: false,
			showDialogOptions: false,
			showDialogAddPayment: false,
			loadingSearchCustomers: false,
			showDialogNewPerson: false,
			documentNewId: null,
			generate: false,
			has_card: false,
			resource: 'fast-sales',
			amount: 0,
			difference: 0,
			button_payment: false,
			series: [],
			all_series: [],
			cancel: false,
			statusDocument: {},
			payment_method_types: [],
			paymentMethodTypeId: null,
			cardBrandId: null,
			showPaymentMethods: false,
			customers: [],
		};
	},
	async created() {
		await this.getTables();
		this.initFormPayment();
		this.inputAmount();

		this.reloadDataCustomers(this.form.customer_id);

		this.$eventHub.$on('reloadDataPeople', (customer_id) => {
			this.reloadDataCustomers(customer_id);
		});
	},
	methods: {
		async addPaymentExact() {
			this.form.payments = [];
			this.amount = this.form.total;
			let payment_method = find(this.payment_method_types, { id: 1 });
			await this.form.payments.push({
				id: null,
				date_of_payment: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 1,
				card_brand_id: null,
				document_id: null,
				command_id: null,
				reference: null,
				payment: this.form.total,
				payment_method: payment_method,
			});
			this.inputAmount();
		},
		async addPaymentExactPlin() {
			this.form.payments = [];
			this.amount = this.form.total;
			let payment_method = find(this.payment_method_types, { id: 9 });
			await this.form.payments.push({
				id: null,
				date_of_payment: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 9,
				card_brand_id: null,
				document_id: null,
				command_id: null,
				reference: null,
				payment: this.form.total,
				payment_method: payment_method,
			});
			this.inputAmount();
		},
		async addPaymentExactYape() {
			this.form.payments = [];
			this.amount = this.form.total;
			let payment_method = find(this.payment_method_types, { id: 10 });
			await this.form.payments.push({
				id: null,
				date_of_payment: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 10,
				card_brand_id: null,
				document_id: null,
				command_id: null,
				reference: null,
				payment: this.form.total,
				payment_method: payment_method,
			});
			this.inputAmount();
		},
		async addPaymentExactMarket() {
			this.form.payments = [];
			this.amount = this.form.total;
			let payment_method = find(this.payment_method_types, { id: 11 });
			await this.form.payments.push({
				id: null,
				date_of_payment: dayjs().format('YYYY-MM-DD'),
				payment_method_type_id: 11,
				card_brand_id: null,
				document_id: null,
				command_id: null,
				reference: null,
				payment: this.form.total,
				payment_method: payment_method,
			});
			this.inputAmount();
		},
		async setAmount(amount) {
			if (this.difference < 0) {
				this.amount = parseFloat(this.amount) + parseFloat(amount);
				let exist = find(this.form.payments, { payment_method_type_id: 1 });
				if (exist) {
					exist.payment += amount;
				} else {
					let payment_method = find(this.payment_method_types, { id: 1 });
					this.form.payments.push({
						id: null,
						date_of_payment: dayjs().format('YYYY-MM-DD'),
						payment_method_type_id: 1,
						card_brand_id: null,
						document_id: null,
						command_id: null,
						reference: null,
						payment: amount,
						payment_method: payment_method,
					});
				}
				this.inputAmount();
			}
		},
		addPaymentWithCard(card_brand_id, payment_method_type_id) {
			this.cardBrandId = card_brand_id;
			this.paymentMethodTypeId = payment_method_type_id;
			this.showDialogAddPayment = true;
		},
		addPaymentSelectMethod() {
			this.showPaymentMethods = true;
			this.showDialogAddPayment = true;
		},
		addPayment(payment) {
			if (this.difference < 0) {
				this.amount = parseFloat(this.amount) + parseFloat(payment.amount);

				if (this.showPaymentMethods) {
					this.paymentMethodTypeId = payment.payment_method_type_id;
					this.cardBrandId = payment.card_brand_id;
				}

				let exist = find(this.form.payments, {
					payment_method_type_id: this.paymentMethodTypeId,
				});

				if (exist) {
					console.log(payment.amount);
					exist.payment = parseFloat(exist.payment) + parseFloat(payment.amount);
				} else {
					let payment_method = find(this.payment_method_types, {
						id: this.paymentMethodTypeId,
					});
					this.form.payments.push({
						id: null,
						date_of_payment: dayjs().format('YYYY-MM-DD'),
						payment_method_type_id: this.paymentMethodTypeId,
						card_brand_id: this.cardBrandId,
						document_id: null,
						command_id: null,
						has_card: true,
						reference: null,
						payment: Number(payment.amount),
						payment_method: payment_method,
					});
				}
				this.inputAmount();
			}
			this.showPaymentMethods = false;
		},
		async clickRemovePayment(index) {
			this.amount = this.amount - this.form.payments[index].payment;

			this.form.payments.splice(index, 1);

			if (this.form.payments.length == 0) {
				this.button_payment = true;
				this.amount = 0;
			}

			this.inputAmount();
		},
		inputAmount() {
			// this.amount = this.form.total
			this.difference = this.amount - this.form.total;
			if (isNaN(this.difference)) {
				this.button_payment = true;
				this.difference = '-';
			} else if (this.difference >= 0) {
				this.button_payment = false;
				this.difference = this.amount - this.form.total;
			} else {
				this.button_payment = true;
			}
			this.difference = round(this.difference, 2);
		},
		initFormPayment() {
			this.difference = -this.form.total;
			this.generate = false;
		},
		async changeDocumentType(document_type_id) {
			this.form.document_type_id = document_type_id;
			this.filterSeries();
		},
		filterSeries() {
			this.form.series_id = null;
			this.series = filter(this.all_series, { document_type_id: this.form.document_type_id });
			this.form.series_id = this.series.length > 0 ? this.series[0].id : null;
		},
		async clickCancel() {
			// this.loadingSubmit = true
			// await this.sleep(500);
			// this.loadingSubmit = false
			this.$eventHub.$emit('cancelSale');
		},
		async clickBack() {
			// this.loadingSubmit = true
			// await this.sleep(500);
			// this.loadingSubmit = false
			this.$eventHub.$emit('backSale');
		},
		sleep(ms) {
			return new Promise((resolve) => setTimeout(resolve, ms));
		},
		async clickPayment() {
			this.loadingSubmit = true;

			if (this.generate) {
				if (!this.form.series_id) {
					return this.$message.error(
						'El comprobante seleccionado de no tiene serie, asignar para continuar'
					);
				}

				if (
					this.form.document_type_id == '01' &&
					this.customer.identity_document_type_id != '6'
				) {
					return this.$message.error(
						'Error! no se puede emitir una factura, el cliente no es válido.'
					);
				}

				await this.$http
					.post(`/documents`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.saveSale();
							this.statusDocument = response.data.data.response;
							this.documentNewId = response.data.data.id;
							this.showDialogOptions = true;
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loadingSubmit = false;
					});
			} else {
				await this.saveSale();
			}
		},
		async saveSale() {
			await this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						// if (!this.generate) {
							// this.$message.success('La venta se ha realizado satisfactoriamente.');
							this.printPreAccount();
							this.sleep(800);
							this.$router.push({ name: 'SaleIndex' });
							// this.$eventHub.$emit('saleSuccess'); 
						// }
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
		searchCustomers(input) {
			if (input.length > 1) {
				this.loadingSearchCustomers = true;
				let parameters = `input=${input}&document_type_id=${this.form.document_type_id}&operation_type_id=0101`;

				this.$http.get(`/documents/search/customers?${parameters}`).then((response) => {
					this.customers = response.data;
					this.loadingSearchCustomers = false;
				});
			} else {
				this.customers = [];
			}
		},
		reloadDataCustomers(customer_id) {
			this.$http.get(`/documents/search/customer/${customer_id}`).then((response) => {
				this.customers = response.data;
				this.form.customer_id = customer_id;
			});
		},
		getTables() {
			this.$http.get(`/${this.resource}/payment-tables`).then((response) => {
				this.all_series = response.data.series;
				this.payment_method_types = response.data.payment_method_types;

				this.filterSeries();
			});
		},
		printPreAccount() {
			this.$http.get(`/commands/print/pre-account/${this.form.id}`).then((response) => {
				if (response.data.success) {
					let data = JSON.stringify(response.data.data);
					let url = `https://print-api.test/print/pre-account`;

					fetch(url, { method: 'POST', body: data })
						.then((dataWrappedByPromise) => dataWrappedByPromise.json())
						.then((data) => {
							if (data.success) {
								this.$message.success(data.message);
							} else {
								this.$message.error(data.message);
							}
						})
						.catch((error) => {
							console.error('Error: ', error);
						});
				} else {
					this.$message.error(response.data.message);
				}
			});
		},
	},
};
</script>
